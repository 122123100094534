import gsap, { Expo, Quint } from "gsap";

function Carousel3D({mode, devMode, fps, button, animationMode, isMobile}){
    this.direction = "right";
    this.oldx = 0;
    this.oldy = 0;
    this.isMobile=isMobile
    this.mode = mode
    this.button = button
    this.mouseX = 0;
    this.mouseY = 0;
    this.mouseZ = 0;
    this.addX = 0;
    this.direction = 0
    this.draggingEl = false
    this.cache= 0
    this.i = 0
    this.animationMode = animationMode
    this.fps_counter = {
    
        tick: function () 
        {
            // this has to clone the array every tick so that
            // separate instances won't share state 
            this.times = this.times.concat(+new Date());
            var seconds, times = this.times;
    
            if (times.length > this.span + 1) 
            {
                times.shift(); // ditch the oldest time
                seconds = (times[times.length - 1] - times[0]) / 1000;
                return Math.round(this.span / seconds);
            } 
            else return null;
        },
    
        times: [],
        span: 20
    };
    this.counter = Object.create(this.fps_counter);
    this.init = () =>
    {
        if(this.mode == "MouseMove"){
            this.button = false
        }
      
        this.btns = document.getElementById("content-btns")
        this.container = document.querySelector( '#contentContainer' );
        this.carousel =  document.querySelectorAll( '#carouselContainer' );
        this.items =  document.querySelectorAll( '.carouselItem' );
        this.inners = document.querySelectorAll(".carouselItemInner")
        this.rY = 360 / this.items.length;
        this.fps = document.getElementById("fps")
        this.radius = this.items.length*100;
        if(this.isMobile){
            this.container.style.marginLeft = "50px"
            this.container.parentElement.style.width = "100%"
            this.container.parentElement.style.height = "100vh"
            this.container.style.position = "static"
            for(let it of this.items){
                it.style.width = "200px"
            }
        }
        if(this.button ){
            const rightButton = document.createElement("div")
            const leftButton = document.createElement("div")
            rightButton.id = "carousel3d_right_btn"
            leftButton.id = "carousel3d_left_btn"
            rightButton.addEventListener("click", (e) => {
                this.i++
                if(!this.items[this.i]){
                    this.i = 0
                }
                this.items[this.i].click()
            })
            leftButton.addEventListener("click", (e) => {
                this.i--
                if(!this.items[this.i]){
                    this.i = this.items.length-1
                }
                this.items[this.i].click()
            })
            leftButton.className = "arrow-left"
            rightButton.className = "arrow-right"
            this.btns.appendChild(leftButton)
            this.btns.appendChild(rightButton)

        }else{
            if(document.getElementById("carousel3d_right_btn") || document.getElementById("carousel3d_left_btn")){
                document.getElementById("carousel3d_left_btn").remove()
                document.getElementById("carousel3d_right_btn").remove()
            }
        }
       
        
        // set container 3d props
        gsap.set(this.container, {perspective:600})
        gsap.set(this.carousel, {z:-(this.radius)})
        for(let i=0; i<this.items.length; i++){
            if(this.animationMode == 1){
                gsap.set(this.items[i], {rotationY:this.rY * i, z:this.radius, transformOrigin:"50% 50% " + -this.radius + "px"});
            }else if(this.animationMode == 2){
                gsap.set(this.items[i], {translateX :400 * i, z:this.radius, transformOrigin:"50% 50% " + -this.radius + "px"});
                
            }
            if(!this.isMobile){
                gsap.set(this.items[0],{scale:1.5})
            }


            if(mode == "Click"){
                this.items[i].addEventListener("click", (e) => onClick(e,this.rY,i))
                this.items[i].style.cursor = "pointer"
            }
            this.animateIn( this.items[i],this.inners[i] )	
        }
        this.container.addEventListener("pointerup", onPointerUp)
        this.container.addEventListener("pointercancel", onPointerUp)
        this.container.addEventListener("pointerleave", onPointerUp)
        
        this.container.addEventListener("pointerdown", onPointerDown)
        window.addEventListener( "pointermove", onMouseMove, false );	
        this.ticker = setInterval( looper, 1000/fps );		
    }
    
    const onPointerUp = (event) => {
        this.draggingEl = false
        this.cache = this.mouseX
        if(this.items[this.itemsNumber]){
            this.items[this.itemsNumber].click()
        }else{
            this.items[0].click()
        }
    }
    this.mousemovemethod = function (e) {
    
        if (e.pageX > this.oldx && e.pageY == this.oldy) {
            this.direction="right";
        }
        else if (e.pageX < this.oldx && e.pageY == this.oldy) {
            this.direction="left";
        }
    
        this.oldx = e.pageX;
        this.oldy = e.pageY;
        
    }
    const onPointerDown = (event) => {
        this.draggingEl = true
    }
    this.animateIn = ( $item, $block ) =>
    {
        var $nrX = 360 * getRandomInt(2);
        var $nrY = 360 * getRandomInt(2);
            
        var $nx = -(2000) + getRandomInt( 4000 )
        var $ny = -(2000) + getRandomInt( 4000 )
        var $nz = -4000 +  getRandomInt( 4000 )
            
        var $s = 1.5 + (getRandomInt( 10 ) * .1)
        var $d = 1 - (getRandomInt( 8 ) * .1)

        if(this.animationMode == 1){
            gsap.set( $item, { autoAlpha:1, delay:$d } )	
            gsap.set( $block, { z:0, rotationY:$nrY, rotationX:$nrX, x:$nx, y:$ny, autoAlpha:0} )
            gsap.to( $block, { delay:$d, rotationY:0, rotationX:0, z:-100,  ease:Expo.easeInOut, duration:$s} )
            gsap.to( $block, { delay:$d, x:0, y:0, autoAlpha:1, ease:Expo.easeInOut, duration: $s-.5} )
        }else if(this.animationMode == 2){
            gsap.set( $item, { autoAlpha:1, delay:$d } )	
            gsap.set( $block, { z:0, x:$nx, y:$ny, autoAlpha:0} )
            gsap.to( $block, { delay:$d,  ease:Expo.easeInOut, duration:$s} )
            gsap.to( $block, { delay:$d, x:0, y:0, autoAlpha:1, ease:Expo.easeInOut, duration: $s-.5} )
        }
        


    }
    const onMouseMove = (event) =>
    {
        if(this.draggingEl){
            this.mousemovemethod(event)
            if(this.direction == "right"){
                this.mouseX -= 1.5
            }else{
                this.mouseX += 1.5
            }
            this.itemsNumber = Math.round(this.mouseX / this.rY)
            if(this.itemsNumber < 0){
                this.itemsNumber = this.items.length + this.itemsNumber
            }
        }else{
            if(this.mode == "MouseMove"){
                this.mouseX = -(-(window.innerWidth * .5) + event.pageX) * .0025;
            }
        }
        
       
        this.mouseY = -(-(window.innerHeight * .5) + event.pageY ) * .01;
        this.mouseZ = !this.isMobile && -(this.radius) - (Math.abs(-(window.innerHeight * .5) + event.pageY ) - 200);    
       
    }
    const onClick = (event,ry,i) => {
        this.mouseX = ry*i
        this.i = i
        this.ry = ry
        if(!this.isMobile){
            gsap.to(this.items[i],{scale:1.35,ease:Quint.easeOut, duration:1})
        }else{
            gsap.to(this.items[i],{ease:Quint.easeOut, duration:1})
        }

        Array.from(this.items).filter(e => e != this.items[i]).forEach(e => gsap.to(e, {scale:1.0,ease:Quint.easeOut, duration:1}))
        
    }
    this.killNumbers = () => {
        for(let i=0; i<this.items.length; i++){
            gsap.killTweensOf( this.items[i],this.inners[i])
        }
        this.mouseX = 0;
        this.mouseY = 0;
        this.mouseZ = 0;
        this.addX = 0;
        this.i = 0

    }

    const looper = () =>
    {
        if(this.mode == "MouseMove"){
            this.addX += this.mouseX
        }else if(this.mode == "Click"){
            this.addX = -this.mouseX
        }
        if(this.animationMode == 1){
            gsap.to( this.carousel,  { rotationY:this.addX, rotationX:this.mouseY, ease:Quint.easeOut, duration:1 } )
            gsap.set( this.carousel, {z:this.mouseZ } )
        }else if(this.animationMode == 2){
            gsap.to( this.carousel,  {translateX: -((this.i) * 400 ), ease:Quint.easeOut, duration:1 } )
            // gsap.set( this.carousel, {z:50} )
        }
        

       
        if(devMode)
            this.fps.innerText = ( 'Framerate: ' + this.counter.tick() + '/60 FPS' )	
    }
    this.setMode = (mode) => {
        this.mode = mode
    }
    const getRandomInt = ( $n ) =>
    {
        return Math.floor((Math.random()*$n)+1);	
    }
}

export default Carousel3D