
import './App.css';
import { useGSAP } from "@gsap/react";
import gsap from "gsap"
import { useEffect, useRef, useState } from 'react';
import Carousel3D from './Library/Carousel3D';
import json from "./projects.json"
function App() {
  const container = useRef()
  const [JSONData,setJSON] = useState(json)
  const [btns, setBtns] = useState([...Object.keys(json), "All"])
  const [loading, setLoading] = useState(true)
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  gsap.registerPlugin(useGSAP);

  const isMobile = width <= 768;
  let carousel
  useEffect(() => {
    setTimeout(() => {
      if(!carousel){
        carousel = new Carousel3D({
          mode : "Click",
          devMode:false,
          fps:60,
          isMobile:isMobile,
          animationMode: isMobile ? 2 : 1,
          button:true
      })
      carousel.init()
      }
    }, 100);
    
  },[loading])
  const open = () => {
    gsap.to(container.current, {display:"block"})
    gsap.fromTo(container.current, {width:20},{width:window.innerWidth}).delay(.5);
    gsap.fromTo(container.current, {height:5}, {height:window.innerHeight}).delay(1)
    gsap.fromTo(".btns", {opacity:0}, {opacity:1}).delay(1)
  }
  const close = () => {
    gsap.to(".btns",{opacity:0}); 
    gsap.to(container.current, {width:0, height:0}).delay(.5)
    gsap.to(container.current, {display:"none"}).delay(1)
  }
  const name = {
    "games":"Games",
    "websites":"Websites",
    "desktopapps":"Desktop Apps",
    "mobileapps":"Mobile Apps",
    "library":"Library",
    "apis":"Rest Api",
    "All":"All Projects"
  }
  return (
    <>
     <header>
        {!isMobile ?btns.reverse().map((e,i) => {
          return  <button key={i} onClick={() => {
            carousel?.killNumbers()
            setJSON(e == "All" ? json : { [e]:json[e]}); 
            setLoading(false)
            setTimeout(() => {
              setLoading(true)
            }, 0);
        }}>{name[e]}</button> 
        }) :
        <button style={{display:"flex", justifyContent:"center", alignItems:"center", gap:5}} onClick={() => open()}>
          <span class="material-symbols-outlined">
          menu
        </span><span>MENU</span></button>}
      </header>
      <svg className='bg' preserveAspectRatio="xMidYMid slice" viewBox="10 10 80 80">
          <path fill="#9b5de5" className="out-top" d="M37-5C25.1-14.7,5.7-19.1-9.2-10-28.5,1.8-32.7,31.1-19.8,49c15.5,21.5,52.6,22,67.2,2.3C59.4,35,53.7,8.5,37-5Z"/>
          <path fill="#f15bb5" className="in-top" d="M20.6,4.1C11.6,1.5-1.9,2.5-8,11.2-16.3,23.1-8.2,45.6,7.4,50S42.1,38.9,41,24.5C40.2,14.1,29.4,6.6,20.6,4.1Z"/>
          <path fill="#00bbf9" className="out-bottom" d="M105.9,48.6c-12.4-8.2-29.3-4.8-39.4.8-23.4,12.8-37.7,51.9-19.1,74.1s63.9,15.3,76-5.6c7.6-13.3,1.8-31.1-2.3-43.8C117.6,63.3,114.7,54.3,105.9,48.6Z"/>
          <path fill="#00f5d4" className="in-bottom" d="M102,67.1c-9.6-6.1-22-3.1-29.5,2-15.4,10.7-19.6,37.5-7.6,47.8s35.9,3.9,44.5-12.5C115.5,92.6,113.9,74.6,102,67.1Z"/>
      </svg>
      
      <div style={{overflow:"hidden"}}>
        <div id="contentContainer" className="trans3d"> 
          {loading && <section  id="carouselContainer" className="trans3d">
            {Object.values(JSONData).flat().map((e,i) => {
              return(
                <figure key={e.id} id={"item"+i} className="carouselItem trans3d">
                  <div className="carouselItemInner trans3d">
                    <div style={{width:"100%", height:"100%", marginTop:-50}}>
                      <p style={{fontSize:14, padding:5}}>{e.description}</p>
                      <img  style={{width:"100%"}} src={e.img == "" ? "https://placehold.co/600x400" : require("./screenshots/"+e.img)} />
                      <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginLeft:10, marginRight:10, marginTop:5, marginBottom:5}}>
                        <div style={e.status == 1 ? {backgroundColor:"green", borderRadius:8, padding:5} : {backgroundColor:"red", borderRadius:8, padding:5}}><p style={{color:"white", fontWeight:"bold", padding:0, margin:0, fontSize:10}}>{e.status == 1 ? "Completed" : "Not Completed"}</p></div>
                        <div style={{gap:5}}>
                        {e['github-link'] && <a target={"_blank"} href={e['github-link']}><svg style={{marginRight:5}} color='rgba(255, 255, 255, 0.75)' aria-hidden="true" className="octicon octicon-mark-github" height="24" version="1.1" viewBox="0 0 16 16" width="24"><path fillRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path>
                          </svg></a>}
                          {e.link && <a target={"_blank"} href={e.link}><svg color='rgba(255, 255, 255, 0.75)' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-160q-33 0-56.5-23.5T80-240v-120h80v120h640v-480H160v120H80v-120q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm300-140-56-58 83-82H80v-80h407l-83-82 56-58 180 180-180 180Z"/></svg></a>}
                        </div>
                      </div>
                     
                    </div>
                  </div>

                </figure>
              )
            })}
          </section>}
        </div>
        <div id="content-btns"></div>
        <div  style={{ backgroundColor:"#1a948d", display:"none", zIndex:9999999999999, position:"absolute", width:"0px", top:"0%", left:"50%", transform:"translateX(-50%)"}} ref={container}>
          <button onClick={() => close()} style={{position:"absolute", right:0, opacity:0, padding:20, border:"none", color:"white", backgroundColor:"transparent"}} className='btns'><span class="material-symbols-outlined">
cancel
</span></button>
          <div style={{display:"flex", flexDirection:"column", marginTop:30, gap:20}}>
          {btns.reverse().map((e,i) => {
            return  <button className='btns' style={{backgroundColor:"transparent", opacity:0, fontSize:20, border:"none", color:"white"}} key={i} onClick={() => {
              carousel?.killNumbers()
              setJSON(e == "All" ? json : { [e]:json[e]}); 
              setLoading(false)
              setTimeout(() => {
                setLoading(true)
              }, 0);
              close()
            }}>{name[e]}</button> 
          })}
          </div>
          
        </div>
      </div>

     
    </>
   
  );
}

export default App;
